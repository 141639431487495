import React from 'react'
import { EnumDialogs, useDialogStore } from "storage/dialog-store"
import { DialogAutenticarSenha } from "../dialog-autenticar-senha/dialog-autenticar-senha";
import { DialogSelecionarMesa } from '../dialog-selecionar-mesa/dialog-selecionar-mesa';

export const DialogModais = () => {
    const { dialogs } = useDialogStore(state => state);
    return <>
        {dialogs[EnumDialogs.Autenticar].aberto && (
            <DialogAutenticarSenha
                aberto={dialogs[EnumDialogs.Autenticar].aberto}
                {...dialogs[EnumDialogs.Autenticar].props}
            />
        )}

        {dialogs[EnumDialogs.SelecionarMesa].aberto && (
            <DialogSelecionarMesa
                aberto={dialogs[EnumDialogs.SelecionarMesa].aberto}
                {...dialogs[EnumDialogs.SelecionarMesa].props}
            />
        )}

    </>
}