import { VariaveisAmbiente } from 'ambiente';
import { useApiBase } from 'api/base';
import { useCallback } from 'react';

export class IdentidadeAutenticarTelefoneProps {
    public constructor(
        public telefone: string = '',
        public senha: string = '',
        public filtros?: {
            contratoId?: string
        }
    ) { }
}

export function usePostIdentidadeAutenticarTelefone() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarTelefone = useCallback(
        (autenticacaoProps: IdentidadeAutenticarTelefoneProps) =>
        invocarApi({
            method: 'POST',
            url: `/v6/Auth/token-telefone`,
            data: autenticacaoProps,
            enviarTokenUsuario: false,
            baseURL: `${VariaveisAmbiente.touchoneApi}/api`
        }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarTelefone,
    };
}
