import { flatten, isEmpty } from 'lodash';
import { useState, useEffect, createRef, useCallback } from 'react';
import { SessoesProps } from './sessoes-props';
import { search } from 'ss-search';

export type SessoesRef = { [key: string]: React.MutableRefObject<HTMLDivElement | null> };

export const useSessoes = ({ sessoes, carregando }: SessoesProps) => {
  const [sessoesRef, definirReferencias] = useState<SessoesRef>({});

  useEffect(() => {
    const resultado = (Array.isArray(sessoes) ? sessoes : [])?.reduce<SessoesRef>(
      (resultado, sessao) => ({
        ...resultado,
        [sessao?.id || '']: createRef<HTMLDivElement | null>(),
      }),
      {},
    );

    if (resultado) {
      definirReferencias(resultado);
    }
  }, [sessoes]);

  const rolarParaSessao = useCallback(
    (sessaoId: string) => {
      const ref = isEmpty(sessoesRef) ? undefined : sessoesRef[sessaoId];
      var scrollableElement = document.body;

      if (Boolean(ref?.current) === false) {
        scrollableElement.addEventListener('wheel', checkScrollUp);
        var intr = setInterval(function () {
          window.scrollTo(0, document.body.scrollHeight);
          if (Boolean(ref?.current)) {
            clearInterval(intr)
          }
        }, 400)
      }
      function checkScrollUp(event) {
        if (checkScrollDirectionIsUp(event)) {
          return clearInterval(intr)
        } else {
          return false
        }
      }

      function checkScrollDirectionIsUp(event) {
        if (event.wheelDelta) {
          return event.wheelDelta > 0;
        }
        return event.deltaY < 0;
      }

      const offset = -110;
      const sessaoTop = ref?.current?.getBoundingClientRect().top || 0;
      const top = sessaoTop + window.pageYOffset + offset;

      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    },
    [sessoesRef],
  );

  const pesquisarSessoes = useCallback((palavraChave?: string) => {
    const sessoesFormatadas = flatten(
      sessoes?.reduce<any[]>((resultado, valorAtual) => {
        const { produtos } = valorAtual;
        const produtosFormatados = produtos?.map((produto) => (
          {
            ...produto,
          }));

        return resultado.concat(produtosFormatados);
      }, []),
    );

    const pesquisarResultado = palavraChave
      ? search(
        sessoesFormatadas,
        ['categoria', 'nome', 'descricao', 'medida', 'valor'],
        palavraChave,
      )
      : [];
    return pesquisarResultado
  },
    [sessoes],
  );

  return {
    dados: {
      sessoesRef,
    },
    operacoes: {
      rolarParaSessao,
      pesquisarSessoes,
    },
  };
};
