import { merge } from 'lodash';
import { IAmbientes, IAmbiente, EnumDeviceType } from './ambiente-tipos';

const deviceType = (Number(process.env.REACT_APP_DEVICE || EnumDeviceType.NAVIGATOR)) as EnumDeviceType;
export const AmbienteEnviado = ('#{Ambiente}#' as keyof IAmbientes) as string;
// const AmbientePadrao = AmbienteEnviado || ('#{Ambiente}#' as keyof IAmbientes);
export const AmbienteEnv = (process.env.REACT_APP_ENV || "prodWeb") as string;
export const TipoBuildEnv = (process.env.REACT_APP_BUILD_TYPE_ENV)
export const ambienteUrl = AmbienteEnv === 'devWeb' ? 'staging' : 'prod'
const isDev = AmbienteEnv !== "prodWeb" //mudar para alternar entre mostrar e não mostrar o controle de queries

let url = `https://api-cardapio-${ambienteUrl}.touchone.com.br/`
let touchoneApiUrl = `https://api-gateway-${ambienteUrl}.touchone.com.br/`
export const keyApiIntegracao = "xNzG0y09et6kWjdhXevogR3H1vuvn3hO"

const Ambientes: IAmbientes = {
  comum: {
    versao: '2.0.12.3',
    isDev: isDev,
    whatsAppLink: 'https://api.whatsapp.com/send?phone=@telefone&text=@mensagem',
    saurusUtilsWebService: 'https://wsmaster.saurus.net.br/v003/servicemaster.asmx',
  },
  menuVip: {
    cardapioApi: url,
    masterApi: url,
    pedidosApi: url,
    authFront: url,
    appName: "MenuVip",
    touchoneApi: touchoneApiUrl,
    deviceType,
  },
};

const Ambiente = (Ambientes[AmbienteEnviado] as IAmbiente) || Ambientes.menuVip;

export const VariaveisAmbiente = merge(Ambientes.comum, Ambiente);
