import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: { justifyContent: 'center', flexWrap: 'wrap' },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '18px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.3)',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,.15)',
            },
        },
    },

    imgContainer: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        maxWidth: '400px',
        maxHeight: '400px',
        minWidth: '400px',
        minHeight: '400px',
        marginBottom: '1rem',
        marginRight: '2rem',
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '200px',
            maxHeight: '200px',
            minWidth: '200px',
            minHeight: '200px',
            marginRight: 'unset',
        },
    },
    container: {
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    info: {
        marginRight: 'unset',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'unset',
            marginRight: 'auto',
        },
    },
    footerContainer: {
        marginTop: 'auto',
    },

    btnConfirmar: {
        [theme.breakpoints.down('sm')]: { flex: '1 1 60%', margin: '2%' },

        marginLeft: '.8rem',
    },
    iconBasket: {
        marginRight: '.5rem',
    },
    seletor: {
        width: '100px',
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        border: '1px solid #9A9A9A',
        alignItems: 'center',
        padding: '3px',
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: { flex: '1 1 30%', margin: '2%' },
    },
    selectorValue: {
        textAlign: 'center',
    },
    adicionar: {
        color: theme.palette.text.primary,
    },
    remover: {
        color: theme.palette.text.primary
    },
    btn: {
        cursor: 'pointer',
    },

    btnsConfirmar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        flexWrap: 'wrap',
        [theme.breakpoints.up('sm')]: { padding: theme.spacing(1), },
        [theme.breakpoints.down('sm')]: { justifyContent: 'center' },
    },
    observacao: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginTop: '1rem',
        },
    },
}));