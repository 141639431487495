import { RetornoApiModel } from 'api/base/api-response-model';
import { IdentidadeAutenticarApelidoProps, usePostIdentidadeAutenticarApelido } from 'api/touchone/requests/post-autenticar-apelido';
import { IdentidadeAutenticarEmailProps, usePostIdentidadeAutenticarEmail } from 'api/touchone/requests/post-autenticar-email';
import { IdentidadeAutenticarTelefoneProps, usePostIdentidadeAutenticarTelefone } from 'api/touchone/requests/post-autenticar-telefone';
import { useCallback } from 'react';
import { validarEmail } from 'utils/valida-email';

interface AuthProps {
  usuario: string;
  senha: string;
  contratoId: string;
  integracao?: boolean
 }

export const useAuth = () => {
  const {
    postIdentidadeAutenticarEmail,
    carregando: carregandoAutenticacaoEmail
  } = usePostIdentidadeAutenticarEmail();
  const {
    postIdentidadeAutenticarApelido,
    carregando: carregandoAutenticacaoApelido
  } = usePostIdentidadeAutenticarApelido();
  const {
    postIdentidadeAutenticarTelefone,
    carregando: carregandoAutenticacaoTelefone
  } = usePostIdentidadeAutenticarTelefone();

  const carregando =
    carregandoAutenticacaoEmail || carregandoAutenticacaoApelido || carregandoAutenticacaoTelefone;

  const authEmail = useCallback(
    async (email: string, senha: string, contratoId: string) => {
      return await postIdentidadeAutenticarEmail(
        new IdentidadeAutenticarEmailProps(email, senha, {
          contratoId: contratoId
        })
      );
    },
    [postIdentidadeAutenticarEmail]
  );

  const authApelido = useCallback(
    async (apelido: string, senha: string, contratoId: string) => {
      return await postIdentidadeAutenticarApelido(
        new IdentidadeAutenticarApelidoProps(apelido, senha, {
          contratoId: contratoId
        })
      );
    },
    [postIdentidadeAutenticarApelido]
  );

  const authTelefone = useCallback(
    async (telefone: string, senha: string, contratoId: string) => {
      return await postIdentidadeAutenticarTelefone(
        new IdentidadeAutenticarTelefoneProps(telefone, senha, {
          contratoId: contratoId
        })
      );
    },
    [postIdentidadeAutenticarTelefone]
  );

  const auth = useCallback(
    async ({ usuario, senha, contratoId}: AuthProps) => {
      let retApi: RetornoApiModel<any>;

      if(/^\d+$/.test(usuario)){
        retApi = await authTelefone(usuario, senha, contratoId);
      } else if (validarEmail(usuario)) {
        retApi = await authEmail(usuario, senha, contratoId);
      } else {
        retApi = await authApelido(usuario, senha, contratoId);
      }

      return retApi
    },
    [authApelido, authEmail, authTelefone]
  );

  return {
    auth,
    carregando
  };
};
