import classNames from "classnames"
import { LoadingOverlayProps } from "./loading-overlay-props"
import { useStyles } from "./loading-overlay-styles";
import { forwardRef } from "react";
import React from "react";

export const LoadingOverlay = forwardRef<HTMLDivElement, LoadingOverlayProps>((props, ref) => {
    const loadingOverlayStyles = useStyles();

    return (
        <div ref={ref} style={props.style} className={classNames(loadingOverlayStyles.overlay, props.variant === 'dark' ? loadingOverlayStyles.overlayDark : loadingOverlayStyles.overlayLight)}>
            <div className={classNames(loadingOverlayStyles.ripple, props.variant === 'dark' ? loadingOverlayStyles.rippleDark : loadingOverlayStyles.rippleLight)}></div>
        </div>
    );
});