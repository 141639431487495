import React, { Fragment, useCallback, useEffect } from 'react';
import { Container, useTheme, useMediaQuery, Box } from '@material-ui/core';
import {
  CardapioHeaderCapa,
  CardapioHeaderLogo,
  CardapioHeaderText,
} from './componentes';
import { Carregando, useUsuarioLogado } from 'componentes';
import { CardapioHeaderProps } from './cardapio-header-props';
import { useCardapioHeader } from './use-cardapio-header';
import { useStyles } from './cardapio-header-styles';
import classNames from 'classnames';
import { CardapioHeaderInfo } from './componentes/cardapio-header-info';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { useAtualizarDadosConfig } from 'componentes/providers/atualizar-configuracoes-tema-provider';

export const CardapioHeader = (props: CardapioHeaderProps) => {

  const { className, atualizando } = props;
  // DEPENDECIAS  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // REQUISIÇÕES
  const { dados, operacoes } = useCardapioHeader({ ...props, cardapioDados: props.cardapioDados });
  const { usuario } = useUsuarioLogado();

  const { empresa, configuracoes } = dados.cardapioDados

  // STYLES
  const classes = useStyles({
    usuarioInvalido: usuario?.invalido(),
  });

  const { salvarHeader, modelFormatado } = useAtualizarDadosConfig();

  const salvarDados = useCallback(() => {
    if (salvarHeader) {
      salvarHeader(configuracoes);
    }
  }, [salvarHeader, configuracoes])

  useEffect(() => {
    salvarDados();
  }, [salvarDados])

  let mesaIdValido: boolean = isValidUUIDV4(props.mesaId);

  return (
    <Container className={classNames(className, classes.root)} >
      <Fragment>
        <CardapioHeaderCapa
          empresaId={empresa.id}
          height={isMobile ? 'calc(100vh - 200px)' : '75vh'}
          capaUrl={configuracoes.capa ?? ''}
          somenteLeitura={!props.configurando}
          quandoUmaNovaCapaForSelecionada={operacoes.quandoOsDadosMudarem}
        >
          <CardapioHeaderLogo
            empresaId={empresa.id}
            somenteLeitura={!props.configurando}
            logoUrl={configuracoes?.logo}
            onLogoChange={operacoes.quandoOsDadosMudarem}
            className={[classes.block, classes.logo].join(' ')}
          />
          <Container fixed>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <div style={{
                padding: theme.spacing(0, 2)
              }}>
                <div style={{
                  background: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.56)' : '#F2F2F28F',
                  borderRadius: 8,
                  padding: 8,
                  backdropFilter: 'blur(2px)'
                }}>
                  <CardapioHeaderText
                    text={empresa.nomeFantasia}
                    somenteLeitura={!props.configurando || true}
                    editando={dados.editandoNome}
                    quandoEdicaoForIniciada={operacoes.quandoEdicaoNomeForIniciada}
                    tipoEdicao="Nome da Empresa"
                    className={classes.block}
                  />
                  <CardapioHeaderText
                    text={configuracoes?.informacaoAdicional}
                    editando={dados.editandoInformacao}
                    somenteLeitura={!props.configurando}
                    quandoEdicaoForIniciada={operacoes.quandoEdicaoInformacoesIniciada}
                    quandoTextoForAlterado={operacoes.quandoInformacoesForAlterada}
                    tipoEdicao="Informações"
                    strong={false}
                    className={classes.block}
                    submitEditar={() => modelFormatado(configuracoes)}
                    variant="h6"
                  />
                  {!props.configurando && (
                    <CardapioHeaderInfo
                      empresa={dados.cardapioDados}
                      idValido={mesaIdValido}
                    />
                  )}
                </div>
              </div>
            </Box>
          </Container>
          <Carregando
            carregando={atualizando || dados.carregando}
            modelo="absolute"
            titulo={atualizando ? 'Atualizando Capa' : 'Buscando Informações'}
            mensagem={
              atualizando
                ? 'Aguarde enquanto atualizamos as configurações do seu estabelecimento.'
                : ''
            }
            className={classes.carregando}
          />
        </CardapioHeaderCapa>
      </Fragment>
    </Container>
  );
};

