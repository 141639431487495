import { VariaveisAmbiente } from 'ambiente';
import { useApiBase } from 'api/base';
import { useCallback } from 'react';

export class IdentidadeAutenticarApelidoProps {
    public constructor(
        public apelido: string = '',
        public senha: string = '',
        public filtros?: {
            contratoId?: string
        }
    ) { }
}

export function usePostIdentidadeAutenticarApelido() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarApelido = useCallback(
        (autenticacaoProps: IdentidadeAutenticarApelidoProps) =>
            invocarApi({
                method: 'POST',
                url: `/v6/Auth/token-apelido`,
                data: autenticacaoProps,
                enviarTokenUsuario: false,
                baseURL: `${VariaveisAmbiente.touchoneApi}/api`
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarApelido,
    };
}
