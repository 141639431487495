import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    sacola: { height: '35px' },
    badge: { bottom: '5px', right: 0 },
    badgeIcon: { bottom: '5px', right: 0 },
    carrinhoIcon: {
      height: '35px',
      width: '35px',
      fill: theme.palette.primary.contrastText,
      padding: 0,
      margin: 0
    }
  }));