import { VariaveisAmbiente } from 'ambiente';
import { useApiBase } from 'api/base';
import { useCallback } from 'react';

export class IdentidadeAutenticarEmailProps {
    public constructor(
        public email: string = '',
        public senha: string = '',
        public filtros?: {
            contratoId?: string
        }
    ) { }
}

export function usePostIdentidadeAutenticarEmail() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarEmail = useCallback(
        (autenticacaoProps: IdentidadeAutenticarEmailProps) => {

            return invocarApi({
                method: 'POST',
                url: `/v6/Auth/token-email`,
                data: autenticacaoProps,
                enviarTokenUsuario: false,
                baseURL: `${VariaveisAmbiente.touchoneApi}/api`
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarEmail,
    };
}
