import { DistanciaModel } from "api/master/models/distancia/distancia-model";

export const useGetLocalizacaoUsuario = () => {

    const obterLocalizacao = (): Promise<DistanciaModel> => {
        return new Promise((resolve, reject) => {
            console.log('Entrou na função obterLocalizacao.')
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                console.log('localizacao', 'Geolocalização não suportada.')
                reject(new Error('Geolocalização não suportada.'));
            }
        });
    }

    return {
        obterLocalizacao,
    }
}
