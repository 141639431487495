import React from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './dialog-adicionar-produto-com-subitem-styles';
import { useCallback, useEffect, useState } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimalString } from 'utils/to-decimal';
import { AvancarIcon } from 'componentes/icons/avancar-icon';
import { VoltarIcon } from 'utils';
import { EnumTipoProduto, EnumTpCalculoModificador, EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { useNavigateSubitens } from './hooks/navigate-subitens';
import { DialogSaurus } from './dialog-saurus/dialog-saurus';
import { useThemeQueries } from './util-styles';
import { CardSubitem } from './components/card-produto-subitem/card-subitem';
import { AddESub } from './components/card-produto-subitem/components/add-e-sub/button-add-e-sub';
import { ConfirmarIcon } from 'componentes/icons/confirmar-icon';
import { Carregando } from 'componentes';
import {
  CardapioStorageKeys,
  useCardapioStorage,
} from 'storage/cardapio-storage';
import { CardapioDadosProps } from 'modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props';
import { TentarNovamenteIcon } from 'componentes/icons/tentar-novamente-icon';
import { ErroColorIcon } from 'componentes/icons/erro-color-icon';
import { MovSimplesModificadoresProdutoModel } from 'api/cardapio/models/produto/mov-simples-modificadores-produto-model';
import { CardSubitemModificador } from './components/card-produto-subitem-modificador/card-subitem-modificador';
import { EnumRoundTo, roundTo } from 'utils/round-to';
import { isEmpty } from 'lodash';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { TextFieldSaurus } from 'componentes/inputs/text-field-saurus';
export interface DialogAdicionarProdutoComSubItensProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
  edit?: boolean;
  fechar: () => void;
  editarProdutoComSubItem: (
    produto: MovSimplesProdutoModel,
    adicionais: MovSimplesProdutoModel[],
    valorFinal: number
  ) => Promise<any>;
  adicionarProdutoComSubItem: (
    produto: MovSimplesProdutoModel,
    adicionais: MovSimplesProdutoModel[],
    valorFinal: number
  ) => Promise<any>;
  cardapioDados: CardapioDadosProps | undefined
  mesaId: string
}

export const DialogAdicionarProdutoComSubItem = ({
  aberto,
  produto,
  edit,
  adicionarProdutoComSubItem,
  editarProdutoComSubItem,
  fechar,
  cardapioDados,
  mesaId
}: DialogAdicionarProdutoComSubItensProps) => {
  // Hooks
  const {
    currentProduct,
    productFinal,
    next,
    goBack,
    productsAdicionais,
    handleAlterQuantity,
    isGoback,
    productInitial,
    carregando,
    erro,
    getAllSubItens,
  } = useNavigateSubitens(produto, edit);

  const { getRegistro } = useCardapioStorage();
  const dadosGerais = getRegistro(
    CardapioStorageKeys.DadosGerais,
    false
  ) as CardapioDadosProps;
  const { empresa, configuracoes } = dadosGerais;
  const theme = useTheme();

  const permiteEntregaRetirada = configuracoes.permiteEntrega || configuracoes.permiteRetirada

  const clienteEstaNaLoja = useCallback((): boolean => {
    //verificar se tem o mesaId e se é válido
    if (!isEmpty(mesaId) && isValidUUIDV4(mesaId))
      return true;

    return false;
  }, [mesaId]);

  // STATES E REFS
  const [qtd, setQtd] = useState<number>(productInitial.qCom);
  const [validButton, setValidButton] = useState<boolean>(
    (currentProduct?.modificadores.filter((m) => m.qAtual < m.qMin) ?? [])
      .length > 0
      ? true
      : false
  );
  const [infoAdicional, setInfoAdicional] = useState<string>(productInitial.observacao);

  const handleSubItem = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      adicionais: MovSimplesProdutoModel[],
      valorFinal: number
    ) => {
      if (!edit)
        await adicionarProdutoComSubItem({ ...produto, observacao: infoAdicional }, adicionais, valorFinal);
      else await editarProdutoComSubItem({ ...produto, observacao: infoAdicional }, adicionais, valorFinal);

      fechar();
    },
    [adicionarProdutoComSubItem, edit, editarProdutoComSubItem, fechar, infoAdicional]
  );

  const retornaProdutoPai = useCallback((modificador: MovSimplesModificadoresProdutoModel | undefined, produtoPai: MovSimplesProdutoModel, prodAtual?: MovSimplesProdutoModel) => {
    let maiorValor = 0
    let valorRatiado = 0
    if (productFinal && modificador) {
      const modificadoresId = produtoPai.modificadores.map(x => x.id)
      const modificadoresIdUnico = produtoPai.modificadores.map(x => x.unicoId)
      const subProdutos = produtoPai.prodSubItem
        .filter((p) =>
          modificadoresId.includes(p.modificadorId ?? '') &&
          modificadoresIdUnico.includes(p.modificadorUnicoId) &&
          p.qCom
        )
      if (subProdutos.length > 0) {
        if (modificador.tpCalculo === EnumTpCalculoModificador.Rateia && prodAtual) {
          valorRatiado = prodAtual.vUnComOrig
        }
        const produtoComMaiorValor = subProdutos.reduce((prev, current) => prev.vUnComOrig > current.vUnComOrig ? prev : current)
        maiorValor = produtoComMaiorValor.vUnComOrig
      }
    }
    if (modificador && modificador.tpCalculo === EnumTpCalculoModificador.Rateia) {
      return valorRatiado
    }
    if (modificador && modificador.tpCalculo === EnumTpCalculoModificador.Maior) {
      return maiorValor
    }
    return produtoPai?.vProd
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const retornaValorPaiAdicionais = useCallback((produto: MovSimplesProdutoModel) => {
    const mod = productFinal.current.modificadores.length > 0 ? productFinal.current.modificadores.find(x => x.id === produto.modificadorId) : null
    if (mod && mod.tpCalculo === EnumTpCalculoModificador.Rateia) {
      const totalDeProdutosSelecionadosRateio = productsAdicionais.filter(item => item.infoSubItem?.modificadorTipoCalculo === EnumTpCalculoModificador.Rateia)
      const somaDosProdutosSelecionados = totalDeProdutosSelecionadosRateio.map(x => x.vUnComOrig).reduce((prev, current) => prev + current, 0)
      const quantidadeDeItensSelecionados = totalDeProdutosSelecionadosRateio.length
      return roundTo((somaDosProdutosSelecionados / quantidadeDeItensSelecionados), 2, EnumRoundTo.MATEMATICO)
    } else if (mod && mod.tpCalculo === EnumTpCalculoModificador.Maior) {
      const produtoComMaiorValor = productsAdicionais.reduce((prev, current) => prev.vUnComOrig > current.vUnComOrig ? prev : current)
      return produtoComMaiorValor.vUnComOrig
    }
    return produto?.vProd
  }, [productFinal, productsAdicionais])

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  useEffect(() => {
    const validarModificadores =
      currentProduct?.modificadores.filter((m) => m.qAtual < m.qMin) ?? [];
    setValidButton(validarModificadores.length > 0 ? true : false);
  }, [currentProduct]);

  const img = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  const imgInfo = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImgInfo}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, classes.cardImgInfo, produto.xProd]
  );

  const subItens = currentProduct?.prodSubItem === undefined ? [] : currentProduct.prodSubItem.filter((prod) => !prod.modificadorId);
  const subItensComPadrao = productInitial.prodSubItem.filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0).filter((p) => p.qCom > 0)

  const valorAcumulado = useCallback((subItens: MovSimplesProdutoModel[], produtoPai: MovSimplesProdutoModel) => {
    const valorDosAdicionais = subItens.reduce(
      (acc, current) => acc
        + (current.vUnCom * (current.qComModificador > 0 ? current.qComModificador : current.qCom))
      , 0);
    return ((valorDosAdicionais ?? 0) + (produtoPai?.vProd ?? 0)) * (qtd)
  }, [qtd])

  return (
    <DialogSaurus
      aberto={aberto || false}
      tamanho='sm'
      titulo={'Personalize seu Produto'}
      isButtonTitleClose
      handleClickClose={fechar}
      removerPaddingContent
      fullScreen={isMobile}
      bottomArea={(
        <Box px={3}>
          {(!erro || erro.length === 0) && (
            <Grid
              container
              spacing={2}
              style={{ marginTop: '8px', marginBottom: '8px' }}
            >
              {currentProduct === null && !(configuracoes.tipoUsoCardapio !== 3 && (clienteEstaNaLoja() || permiteEntregaRetirada)) && (
                <Grid item xs={12}>
                  <Typography variant='body2' align="center" style={{ fontWeight: 500 }}>
                    Você não pode adicionar este produto no modo somente leitura.
                  </Typography>
                </Grid>
              )}
              {isGoback && (
                <Grid item xs={6}>
                  <Button
                    variant='outlined'
                    onClick={goBack}
                    fullWidth
                    className='round'
                    size='large'
                  >
                    <VoltarIcon tipo='BUTTON' />
                    Voltar
                  </Button>
                </Grid>
              )}
              {!carregando && (
                <Grid item xs={isGoback ? 6 : 12}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    className='round'
                    disabled={validButton || (currentProduct === null && !(configuracoes.tipoUsoCardapio !== 3 && (clienteEstaNaLoja() || permiteEntregaRetirada)))}
                    onClick={() => {
                      if (currentProduct === null) {
                        handleSubItem(
                          productFinal.current,
                          productsAdicionais,
                          productFinal.current.vUnCom +
                          productsAdicionais.reduce(
                            (acc, current) => acc + current.vFinal,
                            0
                          )
                        );
                      } else {
                        next();
                      }
                    }}
                  >
                    {currentProduct === null ? (
                      <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    ) : (
                      <AvancarIcon tipo='BUTTON_PRIMARY' />
                    )}
                    {currentProduct === null ? 'Confirmar' : `Total R$ ${toDecimalString(valorAcumulado(productsAdicionais, productFinal.current), 2)}`}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      )}
    >
      <Grid className={classes.cardContainer}>
        <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', padding: '8px 16px' }}>
          {isGoback && !carregando && currentProduct !== null && (
            <div style={{ display: 'flex', marginBottom: 12 }}>
              {imgInfo(productFinal.current.imgUrl)}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Grid>
                  <Typography
                    variant='body1'
                    style={{
                      fontWeight: 400,
                      fontSize: '10px',
                      marginLeft: 8,
                    }}
                  >
                    Produto
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: 8,
                      lineHeight: '18px',
                      marginBottom: '8px',
                    }}
                  >
                    {productFinal.current.xProd}
                  </Typography>
                </Grid>

                {(((productInitial.tpProduto as unknown) as EnumTipoProduto) ===
                  EnumTipoProduto.Produto
                  ? EnumTpProduto.ProdutoComSubItem
                  : EnumTpProduto.Combo) !== EnumTpProduto.Combo && (
                    <Grid>
                      <Typography
                        variant='body1'
                        style={{
                          fontWeight: 400,
                          fontSize: '10px',
                          marginLeft: 8,
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        variant='body1'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          marginLeft: 8,
                          lineHeight: '18px',
                        }}
                      >
                        {toDecimalString(
                          productFinal.current.vUnCom +
                          productsAdicionais.reduce(
                            (acc, current) => acc + current.vFinal,
                            0
                          ),
                          2
                        )}
                      </Typography>
                    </Grid>
                  )}
              </div>
            </div>
          )}

          {erro.length === 0 && (
            <div style={{}}>
              <Box display="flex" width="100%" alignItems="center">
                {img(currentProduct?.imgUrl ?? productFinal.current.imgUrl)}
                <div style={{ flex: 1 }}>
                  <Typography
                    variant='body1'
                    style={{ fontWeight: 400, fontSize: '10px', marginLeft: 8 }}
                  >
                    Produto
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: 8,
                      lineHeight: '18px',
                      marginBottom: '8px',
                    }}
                  >
                    {currentProduct?.xProd ?? productFinal.current.xProd}
                  </Typography>

                  {(((productInitial.tpProduto as unknown) as EnumTipoProduto) ===
                    EnumTipoProduto.Produto
                    ? EnumTpProduto.ProdutoComSubItem
                    : EnumTpProduto.Combo) !== EnumTpProduto.Combo && currentProduct !== null && (
                      <>
                        <Typography
                          variant='body1'
                          style={{
                            fontWeight: 400,
                            fontSize: currentProduct === null ? '12px' : '10px',
                            marginLeft: 8,
                          }}
                        >
                          {'Categoria'}
                        </Typography>

                        <Typography
                          variant='body1'
                          style={{
                            fontWeight: 600,
                            fontSize: '14px',
                            marginLeft: 8,
                            lineHeight: '18px',
                          }}
                        >
                          {currentProduct?.categoria}
                        </Typography>
                      </>

                    )}
                </div>
              </Box>

              {!carregando && currentProduct === null && !edit && (
                <Box display="flex" width='100%' justifyContent={'space-between'} style={{ margin: '.8rem 0' }}>
                  <Box display={"flex"} flexDirection='column' alignItems='flex-start'>
                    <Typography variant="subtitle2" color='textSecondary'>
                      Valor total
                    </Typography>
                    <Typography style={{ fontWeight: 700 }} variant="h5" color='primary'>
                      {`R$ ${toDecimalString(valorAcumulado(productsAdicionais, productFinal.current), 2)}`}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems='center' justifyContent='flex-end'>
                    <AddESub
                      model={productFinal.current}
                      qtd={qtd}
                      setQtd={setQtd}
                      alterarQtdeProduto={(
                        tpProdutoPai: EnumTpProduto,
                        valorProdutoPai: number,
                        produto: MovSimplesProdutoModel,
                        qtd: number,
                        modificador?: MovSimplesModificadoresProdutoModel,
                        prodGradeId?: string,
                        fator?: 'add' | 'sub'
                      ) => {
                        productFinal.current = {
                          ...productFinal.current,
                          qCom: qtd
                        };
                      }}
                      valorProdutoPai={productFinal.current.vProd}
                      noZeroMin
                      tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                    />
                  </Box>
                </Box>
              )}
              {currentProduct?.infAdic && (
                <Grid
                  style={{
                    padding: 8,
                  }}
                >
                  <Typography
                    variant='body1'
                    style={{
                      fontWeight: 400,
                      fontSize: currentProduct === null ? '12px' : '10px',
                    }}
                  >
                    Descrição
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      fontSize: '14px',
                      lineHeight: '18px',
                    }}
                  >
                    {currentProduct.infAdic}
                  </Typography>
                </Grid>
              )}
            </div>
          )}

          {currentProduct === null && !carregando && (
            <Grid container xs={12} style={{ marginBottom: 8 }}>
              <TextFieldSaurus
                label='Observação do Produto'
                tipo='TEXTO'
                min={2}
                max={4}
                multiline
                rows={2}
                rowsMax={4}
                fullWidth
                allowSubmit
                placeholder='Informe uma observação para o produto...'
                onChange={(event) => setInfoAdicional(event.target.value)}
              />
            </Grid>
          )}
          {currentProduct === null &&
            productInitial.prodSubItem
              .filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0)
              .filter((p) => p.qCom > 0).length > 0 && (
              <>
                <Typography variant='h6'>Inclusos no Produto</Typography>
                <Divider className={classes.divider} />
              </>
            )}

          {carregando && (
            <Box mt={2}>
              <Carregando
                carregando
                height={56}
                titulo='Aguarde...'
                className={classes.skeleton}
                tipo='skeleton'
              />
              <Carregando
                carregando
                height={56}
                titulo='Aguarde...'
                className={classes.skeleton}
                tipo='skeleton'
              />
              <Carregando
                carregando
                height={56}
                titulo='Aguarde...'
                className={classes.skeleton}
                tipo='skeleton'
              />
            </Box>
          )}
          <div>
            {subItens.length > 0 &&
              subItens
                .filter((prod) => !prod.modificadorId)
                .map((p) => (
                  <CardSubitem
                    key={p.id}
                    produto={p}
                    handleClickAddOrSub={handleAlterQuantity}
                    edit={edit}
                    qtdPai={productInitial.qCom}
                    valorProdutoPai={currentProduct?.vProd ?? 0}
                    tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                    modificador={productFinal.current.modificadores.length > 0 ? productFinal.current.modificadores.find(x => x.id === p.modificadorId) : null}
                  />
                ))}
          </div>

          {currentProduct?.modificadores &&
            currentProduct?.modificadores?.length > 0 &&
            currentProduct?.modificadores
              .sort((a, b) => a.nome.localeCompare(b.nome))
              .map((modificador) => (
                <Grid style={{ marginRight: 4, marginTop: 16 }}>
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ alignItems: 'center', flex: 1 }}>
                      <Typography variant='h6'>{modificador.nome}</Typography>
                      <Typography variant='caption'>
                        {modificador.descricao}
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {!(modificador.qMin === 1 && modificador.qMax === 1) &&
                        modificador.qMin !== 0 && (
                          <Grid
                            className={classes.badgeInfoModificador}
                            style={{ marginBottom: 4 }}
                          >
                            <Typography
                              variant='caption'
                              style={{ color: theme.palette.common.white }}
                            >
                              Minímo de {modificador.qMin}
                            </Typography>
                          </Grid>
                        )}
                      <Grid className={classes.badgeInfoModificador}>
                        <Typography
                          variant='caption'
                          style={{ color: theme.palette.common.white }}
                        >
                          {modificador.qMin === 1 && modificador.qMax === 1
                            ? 'Obrigatório'
                            : `${modificador.qAtual} de ${modificador.qMax}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />

                  {currentProduct?.prodSubItem
                    .filter((p) => p.modificadorId === modificador.id &&
                      p.modificadorUnicoId === modificador.unicoId)
                    .sort((a, b) => a.ordem - b.ordem)
                    .map((p) => (
                      <CardSubitemModificador
                        key={p.id}
                        produto={p}
                        handleClickAddOrSub={handleAlterQuantity}
                        edit={edit}
                        qtdPai={productInitial.qCom}
                        modificador={modificador}
                        qtdModificador={currentProduct?.prodSubItem
                          .filter(
                            (p) =>
                              p.modificadorId === modificador.id &&
                              p.modificadorUnicoId === modificador.unicoId &&
                              p.qCom > 0
                          )
                          .reduce((acc, current) => acc + current.qCom, 0)}
                        valorProdutoPai={retornaProdutoPai(modificador, currentProduct, p)}
                        tpProdutoPai={currentProduct.tpProduto}
                        listaSubItens={currentProduct.prodSubItem.filter((p) =>
                          p.modificadorId === modificador.id &&
                          p.modificadorUnicoId === modificador.unicoId
                        )}
                      />
                    ))}
                </Grid>
              ))}

          {currentProduct === null && subItensComPadrao.length > 0 && (
            <div>
              {subItensComPadrao
                .filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0)
                .filter((p) => p.qCom > 0)
                .map((p) => {
                  return (
                    <CardSubitem
                      key={p.id}
                      produto={{
                        ...p,
                        qCom:
                          p.qCom > p.infoSubItem!.qPadrao
                            ? p.infoSubItem!.qPadrao
                            : p.qCom,
                      }}
                      handleClickAddOrSub={handleAlterQuantity}
                      verificar
                      edit={edit}
                      qtdPai={productInitial.qCom}
                      valorProdutoPai={productInitial.vProd}
                      tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                      modificador={productFinal.current.modificadores.length > 0 ? productFinal.current.modificadores.find(x => x.id === p.modificadorId) : null}
                    />
                  );
                })}
            </div>
          )}
          {currentProduct === null && productsAdicionais.length > 0 && (
            <>
              <Typography variant='h6'>Adicionais</Typography>
              <Divider className={classes.divider} />
            </>
          )}
          {currentProduct === null && (
            <div>
              {productsAdicionais.length > 0 &&
                productsAdicionais.map((p) => (
                  <CardSubitem
                    key={p.id}
                    produto={p}
                    handleClickAddOrSub={handleAlterQuantity}
                    showValue
                    verificar
                    edit={edit}
                    qtdPai={1}
                    qtdModificador={productsAdicionais
                      .filter(
                        (prod) =>
                          prod.modificadorId === p.modificadorId &&
                          prod.qCom > 0
                      )
                      .reduce((acc, current) => acc + current.qCom, 0)}
                    valorProdutoPai={retornaValorPaiAdicionais(p)}
                    tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                  />
                ))}
            </div>
          )}
        </div>

        {erro.length > 0 && (
          <>
            <Box textAlign='center' mb={2}>
              <Box className={classes.erroIcon}>
                <ErroColorIcon tipo='BUTTON' />
              </Box>
              <Typography>
                Ops, não conseguimos buscar os produtos, tente novamente e caso
                ainda não busque, entre em contato conosco pelo telefone{' '}
                {empresa.fone}. Detalhe: {erro}
              </Typography>
            </Box>
            <Button onClick={getAllSubItens}>
              <TentarNovamenteIcon tipo='BUTTON_PRIMARY' />
              Tentar novamente
            </Button>
          </>
        )}
      </Grid>
    </DialogSaurus>
  );
};
