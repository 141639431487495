import { TokenGestaoModel } from "api/touchone/models/token-gestao-model";
import { decodeToken } from "react-jwt";

export const convertToken = (token?: string): TokenGestaoModel | undefined => {
    let gtoken: TokenGestaoModel | undefined;
    try {
        const utf8 = require('utf8');
        gtoken = decodeToken(token!) as TokenGestaoModel;
        try {
            // consoleDev('convertToken-decode1', gtoken.saudacao)
            // consoleDev('convertToken-decode2', utf8.decode(gtoken.saudacao))
            gtoken.saudacao = utf8.decode(gtoken.saudacao);
        } catch { }
        gtoken.originalToken = token || '';
        gtoken.empresa = JSON.parse(JSON.parse(JSON.stringify(gtoken.empresa)))
        gtoken.modulos = JSON.parse(JSON.parse(JSON.stringify(gtoken.modulos)))
        gtoken.licenca = JSON.parse(JSON.parse(JSON.stringify(gtoken.licenca)))
        gtoken.usuarioId = gtoken.sub || gtoken.jti;
        // consoleDev('convertTokenParse-Final', gtoken);
    } catch (e) {
        // consoleDev('convertTokenParse-Catch', e);
        gtoken = undefined;
    }
    return gtoken;
}