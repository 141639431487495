import classNames from 'classnames';
import { DefaultCardProps } from './default-card-props';
import { useDefaultCardStyles } from './default-card-styles';
import { useCallback, useRef } from 'react';
import React from 'react';
import { LoadingOverlay } from 'componentes/loading-overlay/loading-overlay';

export const DefaultCard = ({
  onClick,
  onCheck,
  isSelected,
  children,
  disabled,
  className,
  hasTagStatus,
  notClickable,
  cardRef,
  useStopPropagation,
  ...props
}: DefaultCardProps) => {
  const classes = useDefaultCardStyles();
  const loadingOverlayRef = useRef<HTMLDivElement>(null)
  const loadingRef = useRef<boolean>(false)

  const handleLoadingButton = useCallback((value: boolean) => {
    if (loadingOverlayRef.current) {
      loadingRef.current = value
      loadingOverlayRef.current.style.display = value ? 'block' : 'none'
    }
  }, [])

  return (
    <>
      <div
        ref={cardRef}
        {...props}
        onClick={async (e) => {
          if (loadingRef.current || disabled) return;
          if (onClick) {
            e.preventDefault();
            if (useStopPropagation) {
              e.stopPropagation();
            }
            handleLoadingButton(true)
          }

          //OBS: setTimeout para garantir que vai dar tempo de fazer o render do botão antes de iniciar o processo do click e travar o DOM
          setTimeout(async () => {
            if (onClick) {
              await onClick(e);
              handleLoadingButton(false);
            }
          }, 20)
        }}
        className={classNames(
          classes.cardContainer,
          'card',
          hasTagStatus === false ? classes.cardWithoutTag : undefined,
          isSelected ? classes.cardSelected : undefined,
          disabled ? classes.cardinativo : undefined,
          !notClickable ? classes.pointer : undefined,
          className,
        )}
      >
        <LoadingOverlay ref={loadingOverlayRef} style={{ display: 'none' }} variant='light' />
        {(hasTagStatus === true || hasTagStatus === undefined) && (
          <div className={classes.tagStatus} style={{ background: "#D9D9D9" }}></div>
        )}
        {children}
      </div>
    </>
  );
};
