import { makeStyles } from '@material-ui/core';
import Background from '../../../../arquivos-estaticos/global/imagens/bg.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1rem',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  containerQRCode: {
    marginTop: theme.spacing(14),
  },
  header: {
    width: '100%',
    maxWidth: '100%',
    padding: 0,
  },
  divider: {
    marginTop: '70px',
    marginBottom: '50px',
    '@media (max-width: 380px)': {
      marginTop: '120px',
      marginBottom: '30px',
    },
  },
  pesquisa: {
    marginBottom: '200px',
  },
  sessao: {
    marginBottom: '50px',
  },
  btnSacola: {
    position: 'fixed',
    display: 'flex',
    right: '25px',
    bottom: '25px',
    zIndex: 1000,
  },
  carregando: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
  },
  background: {
    top: '0',
    left: '0',
    width: '100%',
    filter: 'invert(1)',
    height: '100%',
    opacity: '0.04',
    zIndex: 0,
    position: 'fixed',
    backgroundSize: 'auto 100%',
    backgroundImage: `url('${Background}')`,
    backgroundPosition: 'center',
    backgroundRepeatX: 'repeat',
    backgroundRepeatY: 'no-repeat'
  },
  containerSessaoPesquisa: {
    marginTop: theme.spacing(4),
  }
}));
