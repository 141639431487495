import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    overlayDark: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    rippleDark: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    overlayLight: {
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
    rippleLight: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    overlay: {
        // 'backdrop-filter': 'blur(2px)',
        pointerEvents: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        overflow: 'hidden',
    },
    ripple: {
        position: 'absolute',
        borderRadius: '50%',
        top: '50%',
        left: '50%',
        marginLeft: -5,
        marginTop: -5,
        width: '10px',
        height: '10px',
        animation: `$rippleAnimation 5s ease-out infinite`,
        opacity: 0, // Inicia com opacidade zero para suavidade
    },
    '@keyframes rippleAnimation': {
        '0%': {
            transform: 'scale(0)',
            opacity: 1, // Começa visível
        },
        '70%': {
            transform: 'scale(15)',
            opacity: 0.3, // Quase desaparecendo
        },
        '100%': {
            transform: 'scale(20)',
            opacity: 0, // Desaparece completamente
        },
    },
}));
