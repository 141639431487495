import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardinativo: {
        opacity: 0.6,
        filter: 'grayscale(1)',
    },
    cardContainer: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        padding: theme.spacing(1)
    },
    textInfoPersonalizavel: {
        position: 'absolute',
        top: 3,
        right: 3,
        fontWeight: 400,
        fontSize: 10
    },
    cardImg: {
        display: "flex",
        objectFit: "contain",
        zIndex: 0,
        position: 'relative',
        background: theme.palette.common.white,
        width: "40px",
        height: "40px ",
        borderRadius: "5px",
    },
    invisible: {
        display: 'none'
    }

}));
